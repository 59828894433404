<template>
  <div class="wrapper-container">
    <h2 class="header-2">Customize base</h2>
    <section class="customize">
      <div class="options">
        <OptionsSwitcher
          :tabs="['Background', 'Shirt', 'Skin Tone']"
          :selected="tabSelected"
          @change="tabSelected = $event"
        />
        <div class="color-picker-container">
          <div
            class="color-item"
            :class="{'highlight-active': shouldHighlightColor(color)}"
            v-for="color in colorGroup"
            :key="color"
            :style="{background: color}"
            @click="colorClickHandler(color)"
          />
        </div>
      </div>
      <div>
        <AvatarCircle
          size="350"
          :avatar="store.state.avatar"
        />
      </div>
    </section>
  </div>
</template>

<script setup>
import { computed, ref } from 'vue'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'
import AvatarCircle from '@/components/AvatarCircle'
import OptionsSwitcher from '@/components/OptionsSwitcher'
import { avatarNames } from '@/store/modules/avatar'

const colors = {
  Background: ['#7E4AA7', '#D86743', '#E9CA5C', '#93D3D0', '#4ABC93', '#3992B8'],
  Shirt: ['#FFFFFF', '#BC2247', '#774B37', '#EDEECC', '#3D7C43', '#272524'],
  'Skin Tone': ['#F7D8D0', '#F9CBBB', '#DB9271', '#BE8866', '#845D4F', '#684B41']
}

const store = useStore()
const router = useRouter()

if (!store.state.avatar.base) {
  router.push('/personalize/avatar-builder/1')
}

const tabSelected = ref('Background')

const colorGroup = computed(() => colors[tabSelected.value])

const selectedBackground = computed({
  get: () => store.state.avatar.background_color,
  set: (value) => store.commit(avatarNames.setBackground, value)
})
const selectedShirt = computed({
  get: () => store.state.avatar.shirt_color,
  set: (value) => store.commit(avatarNames.setShirt, value)
})
const selectedSkinTone = computed({
  get: () => store.state.avatar.skin_tone,
  set: (value) => store.commit(avatarNames.setSkinTone, value)
})

const colorClickHandler = (color) => {
  if (tabSelected.value === 'Background') {
    selectedBackground.value = color
  } else if (tabSelected.value === 'Shirt') {
    selectedShirt.value = color
  } else {
    selectedSkinTone.value = color
  }
}

const shouldHighlightColor = (color) => {
  if (tabSelected.value === 'Background') {
    return selectedBackground.value === color
  } else if (tabSelected.value === 'Shirt') {
    return selectedShirt.value === color
  } else {
    return selectedSkinTone.value === color
  }
}
</script>

<style scoped>
.wrapper-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.header-2 {
  margin: 0 0 10px;
  font-family: "Helvetica Neue Lt Std Bold", Arial, sans-serif;
  font-size: 36px;
  color: black;
}

.customize {
  display: flex;
  margin: 90px 0;
  column-gap: 70px;
}

.options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.color-picker-container {
  display: flex;
  flex-wrap: wrap;
  width: 250px;
  margin-top: 50px;
  margin-left: 30px;
  row-gap: 30px;
  column-gap: 30px;
}

.color-item {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.highlight-active {
  transition: box-shadow 0.3s ease;
  outline: 3px solid white;
  box-shadow: 0 0 18px rgba(255, 130, 23, 0.65);
}
</style>
